<template>
  <div>
    <div class="i-layout-page-header header-title">
      <span class="ivu-page-header-title">{{ $route.meta.title }}</span>
    </div>
    <Card :bordered="false" dis-hover class="ivu-mt">
      <Form
        ref="formValidate"
        :model="searchWhere"
        :label-width="100"
        label-position="right"
        @submit.native.prevent>
        <Row :gutter="24" type="flex">
          <Col span="7" class="ivu-text-left">
            <FormItem label="会员类型：">
              <Select v-model="searchWhere.member_type" clearable @on-change="handleSearchs">
                <Option v-for="item in treeSelect" :value="item.id" :key="item.id">{{ item.label }}</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="7" class="ivu-text-left ml20">
            <FormItem label="支付方式：">
              <Select v-model="searchWhere.pay_type" clearable @on-change="handlePaySearchs">
                <Option v-for="item in payList" :value="item.val" :key="item.val">{{ item.label }}</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="7" class="ivu-text-left ml20">
            <FormItem label="购买时间：">
              <DatePicker :editable="false" @on-change="handleChangeTime" :value="timeVal" format="yyyy/MM/dd" type="datetimerange" placement="bottom-start" placeholder="请选择时间" style="width: 90%" :options="options"></DatePicker>
            </FormItem>
          </Col>
          <Col span="7" class="ivu-text-left">
            <FormItem label="搜索：">
              <Input search enter-button @on-search="handleChange" placeholder="请输入用户名称搜索" element-id="name" v-model="searchWhere.name" style="width: 90%; display: inline-table"/>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <Table
        :columns="columns"
        :data="dataList"
        ref="table"
        :loading="loading"
        highlight-row
        no-userFrom-text="暂无数据"
        no-filtered-userFrom-text="暂无筛选结果"></Table>
      <div class="acea-row row-right l-m-t25">
        <Page :total="total" :current="searchWhere.page" :page-size="searchWhere.limit" show-elevator show-total @on-change="handlePageChange"
        />
      </div>
    </Card>
  </div>
</template>

<script>
import mixins from '@/mixins'
import {
  getUsersMemberRecordApi
} from '@/api/marketing'

export default {
  name: 'record',
  mixins: [mixins],
  data () {
    return {
      // 搜索条件
      searchWhere: {
        page: 1,
        limit: 15,
        name: '',
        member_type: '',
        pay_type: ''
      },

      // 会员类型
      treeSelect: [
        { id: 'free', label: '试用' },
        { id: 'card', label: '卡密' },
        { id: 'month', label: '月卡' },
        { id: 'quarter', label: '季卡' },
        { id: 'year', label: '年卡' },
        { id: 'ever', label: '永久' }
      ],
      payList: [
        { val: 'free', label: '免费' },
        { val: 'weixin', label: '微信' },
        { val: 'alipay', label: '支付宝' }
      ],
      timeVal: [],

      // 表格数据
      dataList: [],
      columns: [
        { title: '订单号', key: 'order_id', minWidth: 100 },
        {
          title: '用户名',
          minWidth: 50,
          ellipsis: true,
          render: (h, params) => {
            return h('span', params.row.users.nickname)
          }
        },
        {
          title: '手机号码',
          minWidth: 80,
          render: (h, params) => {
            return h('span', params.row.users.phone || '-无-')
          }
        },
        { title: '会员类型', key: 'member_type', minWidth: 40 },
        {
          title: '有效期限（天）',
          minWidth: 50,
          render: (h, params) => {
            return h('span', params.row.vip_day === -1 ? '永久' : params.row.vip_day)
          }
        },
        { title: '支付金额（元）', key: 'pay_price', minWidth: 50 },
        { title: '支付方式', key: 'pay_type', minWidth: 30 },
        { title: '购买时间', key: 'pay_time', minWidth: 90 }
      ],

      // 时间日期配置
      options: {
        shortcuts: [
          {
            text: '今天',
            value () {
              const end = new Date()
              const start = new Date()
              start.setTime(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()))
              return [start, end]
            }
          },
          {
            text: '昨天',
            value () {
              const end = new Date()
              const start = new Date()
              start.setTime(
                start.setTime(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1))
              )
              end.setTime(
                end.setTime(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1))
              )
              return [start, end]
            }
          },
          {
            text: '最近7天',
            value () {
              const end = new Date()
              const start = new Date()
              start.setTime(
                start.setTime(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 6))
              )
              return [start, end]
            }
          },
          {
            text: '最近30天',
            value () {
              const end = new Date()
              const start = new Date()
              start.setTime(
                start.setTime(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 29))
              )
              return [start, end]
            }
          },
          {
            text: '本月',
            value () {
              const end = new Date()
              const start = new Date()
              start.setTime(start.setTime(new Date(new Date().getFullYear(), new Date().getMonth(), 1)))
              return [start, end]
            }
          },
          {
            text: '本年',
            value () {
              const end = new Date()
              const start = new Date()
              start.setTime(start.setTime(new Date(new Date().getFullYear(), 0, 1)))
              return [start, end]
            }
          }
        ]
      }
    }
  },
  mounted () {
    this.getMemberRecordRequest()
  },
  methods: {
    // 获取订单记录
    getMemberRecordRequest () {
      this.loading = true
      getUsersMemberRecordApi(this.searchWhere).then((res) => {
        this.loading = false
        const { list, count } = res.data
        this.dataList = list
        this.total = count
      }).catch((err) => {
        this.loading = false
        this.$Message.error(err.msg)
      })
    },
    // 条件搜索
    handleSearchs () {},
    // 日期搜索
    handleChangeTime () {},
    // 支付方式搜索
    handlePaySearchs () {},
    // 用户名称搜索
    handleChange () {},
    // 分页搜索
    handlePageChange (index) {}
  }
}
</script>

<style scoped>

</style>
